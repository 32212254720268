<template>
  <div>
    <loading v-if="Loading"/>
    <b-row>
      <b-col class="col-4">
        <b-card style="text-align: center;">
          <b-button variant="warning" @click="clearDPO">Manual Clear DPO</b-button>
        </b-card>
      </b-col>
      <b-col class="col-4">
        <b-card style="text-align: center;">
          <b-button variant="warning" @click="clearDPK">Manual Clear DPK</b-button>
        </b-card>
      </b-col>
      <b-col class="col-4">
        <b-card style="text-align: center;">
          <b-button variant="warning" @click="clearCrime">Manual Clear Catatan Kriminal</b-button>
        </b-card>
      </b-col>
      <b-col class="col-4">
        <b-card style="text-align: center;">
          <b-button variant="warning" @click="resyncPolice">Resync Data Anggota Kepolisian</b-button>
        </b-card>
      </b-col>
      <b-col class="col-4">
        <b-card style="text-align: center;">
          <b-button variant="warning" @click="clearRegistration">Clear Registration List</b-button>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <!-- Switch untuk service_otp -->
      <b-col class="col-4">
        <b-card style="text-align: center;">
          <b-form-checkbox 
            v-model="services.service_otp" 
            switch 
            @change="toggleService('service_otp')"
          >
            Service OTP
          </b-form-checkbox>
        </b-card>
      </b-col>

      <!-- Switch untuk service_onduty -->
      <b-col class="col-4">
        <b-card style="text-align: center;">
          <b-form-checkbox 
            v-model="services.service_onduty" 
            switch 
            @change="toggleService('service_onduty')"
          >
            Service On Duty
          </b-form-checkbox>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from "axios";
import Loading from '@/templates/Loading.vue'

export default {
  components: {
    Loading,
    axios
  },
  data() {
    return {
      Loading: false,
      services: {
        service_otp: false,
        service_onduty: false,
      },
    };
  },
  methods: {
    init(){
      this.fetchServices();
    },
    async fetchServices() {
      try {
        const response = await axios.get("/idp/master/manual/get-services");
        this.services.service_otp = response.data.service_otp === 1;
        this.services.service_onduty = response.data.service_onduty === 1;
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    },
    async toggleService(serviceName) {
      try {
        const newValue = this.services[serviceName] ? 1 : 0;
        await axios.post("/idp/master/manual/update-services", {
          config_name: serviceName,
          config_value: newValue,
        });
      } catch (error) {
        console.error("Error updating service:", error);
      }
    },
    clearDPO(){
      this.$swal({
        title: "Bersihkan DPO yang masa akhirnya jatuh pada Hari ini?",
        icon: "question",
        background: "black",
        showCancelButton: true,
        confirmButtonColor: "#7367f0",
        cancelButtonColor: "#ea5455",
        confirmButtonText: "Save",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            axios.get("cron/clearence-dpo").then((response) => {
              if(response.data.status == 2000){
                this.$swal({
                  title: "Success",
                  icon: "question",
                  background: "black",
                  confirmButtonColor: "#7367f0",
                  confirmButtonText: "Close",
                });
              }
            });
        }
      });
    },
    clearDPK(){
      this.$swal({
        title: "Bersihkan DPK yang masa akhirnya jatuh pada Hari ini?",
        icon: "question",
        background: "black",
        showCancelButton: true,
        confirmButtonColor: "#7367f0",
        cancelButtonColor: "#ea5455",
        confirmButtonText: "Save",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            axios.get("cron/clearence-dpk").then((response) => {
              if(response.data.status == 2000){
                this.$swal({
                  title: "Success",
                  icon: "question",
                  background: "black",
                  confirmButtonColor: "#7367f0",
                  confirmButtonText: "Close",
                });
              }
            });
        }
      });
    },
    clearCrime(){
      this.$swal({
        title: "Bersihkan Semua Data Kriminal? biasa dilakukan di awal bulan",
        icon: "question",
        background: "black",
        showCancelButton: true,
        confirmButtonColor: "#7367f0",
        cancelButtonColor: "#ea5455",
        confirmButtonText: "Save",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          axios.get("cron/clearence-crime").then((response) => {
            if(response.data.status == 2000){
              this.$swal({
                title: "Success",
                icon: "question",
                background: "black",
                confirmButtonColor: "#7367f0",
                confirmButtonText: "Close",
              });
            }
          });
        }
      });
    },
    resyncPolice(){
      this.$swal({
        title: "Re Syncronize Data Anggota Kepolisian?",
        icon: "question",
        background: "black",
        showCancelButton: true,
        confirmButtonColor: "#7367f0",
        cancelButtonColor: "#ea5455",
        confirmButtonText: "Save",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          axios.get("cron/add-resync-police").then((response) => {
            if(response.data.status == 2000){
              this.$swal({
                title: "Success, Harap menunggu Proses Resync, karena dilakukan bertahap. +- 1 jam",
                icon: "question",
                background: "black",
                confirmButtonColor: "#7367f0",
                confirmButtonText: "Close",
              });
            }
          });
        }
      });
    },
    clearRegistration(){
      this.$swal({
        title: "Hapus List Pendaftaran?",
        icon: "question",
        background: "black",
        showCancelButton: true,
        confirmButtonColor: "#7367f0",
        cancelButtonColor: "#ea5455",
        confirmButtonText: "Save",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          axios.get("idp/master/registration/clear").then((response) => {
            if(response.data.status == 2000){
              this.$swal({
                title: "Success, Harap menunggu Proses Resync, karena dilakukan bertahap. +- 1 jam",
                icon: "question",
                background: "black",
                confirmButtonColor: "#7367f0",
                confirmButtonText: "Close",
              });
            }
          });
        }
      }); 
    }
  },
  mounted(){
    this.init();
  }
}
</script>